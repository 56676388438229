<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <!-- <vuexy-logo /> -->
        <b-img :src="appLogoImage" alt="logo" />
        <h2 class="brand-text text-white ml-1 mb-0 d-flex align-items-center">
          iCMS
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="6" class="d-none d-lg-flex align-items-center left-back p-0">
        <div class="w-100 d-lg-flex align-items-center justify-content-center">
          <b-img style="height:100vh" fluid :src="imgUrl" alt="Login V2" />
        </div>
        <div style="font-size:35px;font-weight: bolder;" class="w-100 text-white text-center position-absolute login-left-bottom">
         <p> Your place to Manage.</p> 
         <p class="mt-2">Plan. Create. Control.</p>
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="6" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="9" class="px-xl-2 mx-auto">
          <b-card-title
          style="font-weight: bolder;"
            class="mb-3 text-center"
            title-tag="h2"
          >
            Sign In to iCMS
          </b-card-title>

          <b-alert
            variant="danger"
            :show="loginFormStatus"
          >
            <div class="alert-body font-small-2">
              <p>
                <small class="mr-50"><span class="font-weight-bold">{{loginFormError}}</span></small>
              </p>
            </div>
            <feather-icon
              v-b-tooltip.hover.left="'Close'"
              icon="XIcon"
              size="18"
              @click="loginFormStatus = false"
              class="position-absolute"
              style="top: 10; right: 10;"
            />
          </b-alert>

          <!-- form -->
          <validation-observer ref="loginForm" #default="{ invalid }">
            <b-form class="auth-login-form mt-2" @submit.prevent="login">
              <!-- email -->
              <b-form-group
                class="mb-2"
                label="Email Address"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    @input="loginFormStatus = false"
                    placeholder="Enter Email"
                    class="border-radius"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group class="mb-2">
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                     @input="loginFormStatus = false" 
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge border-radius-pass"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text class="border-radius-icon">
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group class="mb-2">
                <div class="d-flex justify-content-between">
                  <div class="d-flex align-items-center">
                  <b-form-checkbox
                    id="remember-me"
                    v-model="rememberMe"
                    name="checkbox-1 "
                  >
                  </b-form-checkbox>
                  <span class="pl-1 m-0 h5 pt-25"> 
                    Remember Me</span>
                    </div>
                  <!-- <b-link :to="{name:'auth-forgot-password'}"> -->
                  <b-link to="forgot-password">
                    <small>Forgot Password?</small>
                  </b-link>
                </div>
              </b-form-group>

              <!-- submit buttons -->
              <div class="d-flex justify-content-center">
              <b-button
              style="width:30%"
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
                class="border-radius"
              >
                Sign in
                <feather-icon icon="ArrowRightIcon" />
              </b-button>
              </div>
            </b-form>
          </validation-observer>

          <!-- <b-card-text class="text-center mt-2">
            <span>New on our platform? </span>
            <b-link :to="{name:'auth-register'}">
              <span>&nbsp;Create an account</span>
            </b-link>
          </b-card-text> -->
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import VueCookies from 'vue-cookies';
import { ValidationProvider, ValidationObserver } from "vee-validate";

import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
} from "bootstrap-vue";
import useJwt from "@/auth/jwt/useJwt";
import { required, email } from "@validations";
import { storeUserData } from "@/auth/utils";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import appLogoImage from "../../../@core/assets/images/white-logo.svg";
import Cookies from "js-cookie";
import axios from "axios";
axios.defaults.withCredentials = true

export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      baseURL: process.env.VUE_APP_BASE_URL,
      host:window.location.origin,
      rememberMe: false,
      loginFormError:'',
      loginFormStatus:false,
      password: "",
      userEmail: "",
      sideImg: require("@/assets/images/pages/login-left.svg"),
      // sideImg: require('@/assets/images/pages/login-v2.svg'),

      // validation rules
      required,
      email,
      appLogoImage,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeOffIcon" : "EyeIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
   created() {
    const rememberedUsername = VueCookies.get('username');
    const rememberedPassword = VueCookies.get('password');

    if (rememberedUsername && rememberedPassword) {
      this.userEmail = rememberedUsername;
      this.password = rememberedPassword;
      this.rememberMe = true; 
    }
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then((success) => {
        if (success) {
          var formData = new FormData();
          formData.append("email", this.userEmail);
          formData.append("password", this.password);
          const url = `${this.baseURL}/user/login`;
          // axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'; 
          //    axios.defaults.headers.common["Access-Control-Allow-Methods"] ="POST , PUT";
          //    axios.defaults.headers.common["Access-Control-Allow-Credentials"]= "true";
          //    axios.defaults.headers.common['Access-Control-Request-Headers'] = 'X-Wsse';
          //    axios.defaults.headers.common['Access-Control-Max-Age'] = '3600';
          //    axios.defaults.headers.common['Access-Control-Expose-Headers'] = 'X-Pagination-Current-Page';
          axios({
            method: "post",
            url: url,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
            withCredentials: true,
          })
          .then((res) => {
            console.log(res,"res======");
            if (res.data.data) {
              
              useJwt.setToken(res.data.data.access_token)
              useJwt.setRefreshToken(res.data.data.refresh_token)
              this.$ability.update(res.data.ability)
              var payload = useJwt.decodeJwt(res.data.data.access_token);
              console.log(payload);
              storeUserData(payload);
              const userData = payload
              // localStorage.setItem('userData', JSON.stringify(userData))
              // Cookies.set("userData", JSON.stringify(res.data.data.userData));
              Cookies.set("token", res.data.data.access_token);
              Cookies.set("id", userData.user_id);
              // this.$ability.update(res.data.data.userData.ability)
              this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
              
              .then(() => {
                if (this.rememberMe) {
                  VueCookies.set('username', this.userEmail, '30d'); // Store the username for 30 days
                  VueCookies.set('password', this.password, '30d'); // Store the password for 30 days (Note: Not recommended for actual password storage)
                } else {
                  VueCookies.remove('username');
                  VueCookies.remove('password');
                }
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Welcome ${userData.fullName || userData.username}`,
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
                    },
                  })
                })
                .catch((error) => {console.log(error,"err")});
              // this.$router.push({ name: 'dashboard' });
            } else {
              alert(res.message);
            }
          })
          .catch((error) => {
            if(error.toString().includes('401')){
              this.loginFormError = error.response.data.message
              this.loginFormStatus = true
            }
          });
          // useJwt.login({
          //   email: this.userEmail,
          //   password: this.password,
          // })
          //   .then(response => {
          //     const { userData } = response.data
          //     useJwt.setToken(response.data.accessToken)
          //     useJwt.setRefreshToken(response.data.refreshToken)
          //     localStorage.setItem('userData', JSON.stringify(userData))
          //     this.$ability.update(userData.ability)

          //     // ? This is just for demo purpose as well.
          //     // ? Because we are showing eCommerce app's cart items count in navbar
          //     this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)

          //     // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
          //     this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
          //       .then(() => {
          //         this.$toast({
          //           component: ToastificationContent,
          //           position: 'top-right',
          //           props: {
          //             title: `Welcome ${userData.fullName || userData.username}`,
          //             icon: 'CoffeeIcon',
          //             variant: 'success',
          //             text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
          //           },
          //         })
          //       })
          //   })
          //   .catch(error => {
          //     this.$refs.loginForm.setErrors(error.response.data.error)
          //   })
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";

.left-back {
  background: #3f8cff;
}
.login-left-bottom {
  bottom: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.border-radius {
  border-radius: 12px;
}
.border-radius-pass {
  border-radius: 12px;
}
.border-radius-icon .input-group-text {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}
</style>
